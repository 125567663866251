'use client';

import type { SearchFieldProps } from '.';

import { useEffect, useRef, useState } from 'react';

import Button from '@components/atoms/button';
import Link from '@components/atoms/link';
import Image from '@components/atoms/image';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { useLocale } from 'next-intl';

import getSuggestProducts from '@api/get-suggest-products';
import Spinner from 'react-bootstrap/Spinner';
import formatPrice from '@utils/format-price';
import FormControl from 'react-bootstrap/FormControl';
import Delivery from '@components/molecules/delivery';
import Tax from '@/components/atoms/tax';
import t from '@/utils/translation-faker';
import useDebounce from '@hooks/useDebounce';

const SearchField = ({ labels }: SearchFieldProps) => {
    const router = useRouter()
    const locale = useLocale()

    const [search, setSearch] = useState<string>('');
    const [focused, setFocused] = useState<boolean>(false);

    const [categories, setCategories] = useState<{ elements: any[], totalCount: number | null }>({ elements: [], totalCount: null })
    const [manufacturer, setManufacturer] = useState<{ elements: any[], totalCount: number | null }>({ elements: [], totalCount: null })
    const [products, setProducts] = useState<{ elements: any[], totalCount: number | null }>({ elements: [], totalCount: null })

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const ref = useRef<any | null>(null);

    const fetch = async (search: string) => {
        setIsLoading(true)

        const { elements, extensions, total }: any = await getSuggestProducts({ search })
        setProducts({ elements, totalCount: total })

        if (extensions) {
            const { acrisCategorySearchResult, acrisManufacturerSearchResult } = extensions

            setManufacturer({ elements: acrisManufacturerSearchResult.elements, totalCount: acrisManufacturerSearchResult.total })
            setCategories({ elements: acrisCategorySearchResult.elements, totalCount: acrisCategorySearchResult.total })
        }

        setIsLoading(false)
    }

    /**
     * @TODO add event types
     * @param e 
     */
    const handleClickOutside = (e: any) => {
        e.stopPropagation()
        if (!ref.current?.parentNode.contains(e.target)) {
            setFocused(false)
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    // DeBounce Function
    useDebounce(() => {
        if (2 <= search?.length) {
            fetch(search)
        }
    }, [search], 200)


    useEffect(() => {
        if (true === focused) {
            document?.querySelector("body")?.classList.add('overflow-hidden')
        } else {
            document?.querySelector("body")?.classList.remove('overflow-hidden')
        }
    }, [focused])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (value.length < search.length && 2 >= search.length && 2 > value.length) {
            setSearch('')
            setProducts({ elements: [], totalCount: null })
            setManufacturer({ elements: [], totalCount: null })
            setCategories({ elements: [], totalCount: null })
        }

        setSearch(value);
    };

    const handleClick = () => {
        setSearch('')
        setProducts({ elements: [], totalCount: null })
        setManufacturer({ elements: [], totalCount: null })
        setCategories({ elements: [], totalCount: null })

        setFocused(false)
    }

    const handleSubmit = (showAll = false) => {
        if (false === showAll && products?.elements && Array.isArray(products?.elements) && 1 === products?.elements.length && search === products?.elements[0].productNumber) {
            handleClick()
            router.push((!products?.elements?.[0]?.seoUrls?.[0]?.seoPathInfo.startsWith('/') ? '/' : '') + products?.elements?.[0]?.seoUrls?.[0]?.seoPathInfo)
        } else {
            handleClick()
            router.push(`/search${'' !== search ? '?sSearch=' + search : ''}`)
        }
    }

    return (
        <div className={'position-relative  d-flex  align-items-stretch  flex-grow-1  order-3  order-md-1  px-1  px-xxl-5  my-2  my-md-0  me-2  me-md-0'}>
            <FormControl
                id={'search-field'}
                ref={ref}
                className={'rounded-0'}
                placeholder={labels.placeholder}
                value={search}
                onChange={handleChange}
                onFocus={() => setFocused(true)}
                onKeyUp={event => {
                    if (event.key === "Enter") {
                        handleSubmit()
                    }
                }}
            />
            <Button id={'search-field-button'} className={'rounded-0 px-3'} icon={'search'} iconVariant={'white'} variant={'secondary'} aria-label={labels.label} onClick={() => handleSubmit()} />
            {focused && (
                <div
                    className={classNames('search-field-entries  overflow-auto  fixed-top  position-xxl-absolute  left-0  flex-column  bg-white  search-field-border-color  visible  m-0  mt-1 mx-0  mx-xxl-5', {
                        'd-flex': (search && '' != search && 2 <= search.length),
                        'd-none': !(search && '' != search && 2 <= search.length)
                    })}
                    style={{ overflow: 'y-scroll', zIndex: 2000 }}
                >
                    <div className={'d-flex  flex-column-reverse  flex-xxl-row  gap-4  gap-xxl-0  p-3  border-0  border-xxl  border-bottom-0  border-bottom-xxl-0'}>
                        {(products?.elements && Array.isArray(products?.elements) && !(1 === products?.elements.length && search === products?.elements[0].productNumber)) && (
                            <div className={'d-flex  flex-column pe-0  pe-xxl-5  border-end-0  border-end-xxl'}>
                                <div className={'d-flex  flex-column  pb-4'}>
                                    <h5 className={'fs-8'}>
                                        <span className={'fw-semibold  text-decoration-underline'}>{labels.search_suggest_headline_categories}</span>
                                        {(null !== categories.totalCount && 0 < categories.totalCount) && <>&nbsp;<span className={'fs-9'}>({categories.totalCount})</span></>}
                                    </h5>
                                    {false === isLoading ? (
                                        0 < categories?.elements.length ? (
                                            categories.elements.map(({ name, seoUrls }: { name: string, seoUrls: any }, index: number) => (
                                                <Link key={name + index} href={seoUrls?.[0]?.seoPathInfo} className={'text-decoration-none  text-nowrap  fs-8  mb-1'} onClick={handleClick}>
                                                    {name}
                                                </Link>
                                            ))
                                        ) : (
                                            <div className={'text-nowrap  fs-8'}>{t(/*'no_categories'*/ 'Keine Kategorien gefunden')}</div>
                                        )
                                    ) : (
                                        <div className={'d-none d-xxl-flex  flex-grow-1 justify-content-center align-items-center  py-2'}>
                                            <Spinner animation="border" size="sm" variant="secondary" />
                                        </div>
                                    )}
                                </div>
                                <div className={'d-flex  flex-column  pb-0  pb-xxl-4'}>
                                    <h5 className={'fs-8'}>
                                        <span className={'fw-semibold  text-decoration-underline'}>{labels.search_suggest_headline_manufacturer}</span>
                                        {(null !== manufacturer.totalCount && 0 < manufacturer.totalCount) && <>&nbsp;<span className={'fs-9'}>({manufacturer.totalCount})</span></>}
                                    </h5>
                                    {false === isLoading ? (
                                        0 < manufacturer?.elements.length ? (
                                            manufacturer.elements.map(({ name, media, customFields }: { name: string, media: any, customFields: any }, index: number) => (
                                                customFields?.acris_search_manufacturer_seo_url ? (
                                                    <Link
                                                        key={name + index}
                                                        href={customFields.acris_search_manufacturer_seo_url}
                                                        className={'d-flex  align-items-center  text-decoration-none  fs-8  gap-2'}
                                                        onClick={handleClick}
                                                    >
                                                        <Image src={media?.url} className={'object-fit-contain'} width={36} height={36} alt={media?.alt ?? name} title={media?.title ?? name} />
                                                        {name}
                                                    </Link>)
                                                    : (
                                                        <span key={name + index} className={'d-flex  align-items-center  text-decoration-none  fs-8  gap-2'}>
                                                            <Image src={media?.url} className={'object-fit-contain'} width={36} height={36} alt={media?.alt ?? name} title={media?.title ?? name} />
                                                            {name}
                                                        </span>
                                                    )
                                            ))
                                        ) : (
                                            <div className={'text-nowrap  fs-8'}>{labels.no_manufacturer}</div>
                                        )
                                    ) : (
                                        <div className={'d-none d-xxl-flex  flex-grow-1 justify-content-center align-items-center  py-2'}>
                                            <Spinner animation="border" size="sm" variant="secondary" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className={classNames('d-flex  flex-column  flex-grow-1  pb-2', { '  ps-0  ps-xxl-4': (products?.elements && Array.isArray(products.elements) && !(1 === products.elements.length && search === products.elements[0].productNumber)) })}>
                            {(products?.elements && Array.isArray(products.elements) && !(1 === products.elements.length && search === products.elements[0].productNumber)) && (
                                <h5 className={'fs-8  mb-2'}>
                                    <span className={'fw-semibold  text-decoration-underline'}>{labels.search_suggest_headline_products}</span>{(null !== products.totalCount && 0 < products.totalCount) && <>&nbsp;<span className={'fs-9'}>({products.totalCount})</span></>}
                                </h5>
                            )}
                            {false === isLoading ? (
                                (products.elements && Array.isArray(products.elements) && 0 < products.elements.length) ? (
                                    products.elements.map(({ productNumber, calculatedCheapestPrice, referenceUnit, purchaseUnit, seoUrls, cover, name, availableStock, minPurchase, customFields, deliveryTime, childCount, taxId }: any, index: number) => (
                                        <Link
                                            key={productNumber}
                                            href={seoUrls?.[0]?.seoPathInfo}
                                            className={classNames('d-flex text-decoration-none text-dark fs-8 gap-4', { 'border-bottom  mb-3  pb-3': index < products.elements.length - 1 })}
                                            onClick={handleClick}
                                        >
                                            <Image src={cover?.media?.url} className={'object-fit-contain'} width={48} height={48} alt={cover?.media?.alt ?? name} title={cover?.media?.title ?? name} />
                                            <span className={'position-relative d-inline-block fs-8  w-100'}>
                                                <span className={'fw-semibold'}>{name}</span>
                                                {null !== customFields.product_main_feature && <span className={'d-block  fs-9  fw-semibold'}>{customFields.product_main_feature}{customFields.product_main_feature_additional && ' ' + customFields.product_main_feature_additional}</span>}
                                                {0 >= childCount && (
                                                    <>

                                                        <span className={'d-inline-flex gap-2'}>
                                                            <Delivery
                                                                deliveryTime={deliveryTime}
                                                                availableStock={availableStock}
                                                                showDeliveryTime={false}
                                                                minPurchase={minPurchase}
                                                            />
                                                            <span className={'d-block Paragraph-Lead-Muted fs-9'}>{labels.product_number_shortest}: <span className={'fw-semibold'}>{productNumber}</span></span>
                                                        </span>
                                                    </>
                                                )}
                                                <span className={'position-absolute  bottom-0  end-0  text-end  fw-semibold'}>
                                                    {((false === customFields?.product_price_upon_request && true !== customFields?.product_rental_offer_request) && 0 < calculatedCheapestPrice?.unitPrice) ? (
                                                        <>
                                                            {calculatedCheapestPrice.hasRange && labels.from} {formatPrice({
                                                                value: calculatedCheapestPrice.unitPrice,
                                                                referenceUnit,
                                                                purchaseUnit,
                                                                locale,
                                                                currencyId: 'de-CH' === locale ? 'CHF' : 'EUR'
                                                            })}
                                                            {(locale && ['de-DE'].includes(locale)) && (
                                                                <Tax taxId={taxId} className={'w-100  d-block  fs-9  fw-semibold text-gray-500'} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>{t(true === customFields?.product_rental_offer_request ? 'Mietangebot auf Anfrage' : 'Preis auf Anfrage')}</>
                                                    )}
                                                </span>
                                            </span>
                                        </Link>
                                    ))
                                ) : (
                                    <div className={'text-nowrap  fs-8'}>{t('Keine Produkte gefunden')}</div>
                                )
                            ) : (
                                <div className={'d-flex  flex-grow-1  justify-content-center  align-items-center'}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                            )}
                        </div>
                    </div>
                    {(products?.elements && Array.isArray(products.elements) && !(1 === products.elements.length && search === products.elements[0].productNumber)) && (
                        <div className={'text-center  border-top  border-1  bg-secondary  shadow-sm'}>
                            <Button
                                className={'justify-content-center w-100'}
                                icon={'search'}
                                iconWidth={'sm'}
                                iconVariant={'white'}
                                variant={'secondary'}
                                type={'submit'}
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={() => {
                                    setFocused(false)
                                    router.push(`/search/${search}`)
                                }}
                            >
                                {labels.search_more_products}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchField;

