'use client'

import { event as googleEvent } from "nextjs-google-analytics";

const googleSendEvent = (event: string, ntmData: any, callback?: () => void) => {
    const { CookieFirst }: any = window

    if (CookieFirst) {
        if (true === CookieFirst.consent?.advertising) {
            googleEvent(event, ntmData);
        }

        if (callback) callback();
    }
}

export default googleSendEvent
