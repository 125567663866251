'use client'

import React, { useEffect, useState } from 'react'
import Script from 'next/script'

export type ADParams = {
    adsDefenderId: string
}

export const sendADEvent = () => {
    let w: any = window

    if (w?.hc_tm) {
        console.log('sendADEvent')
        w.hc_tm = w.hc_tm || [];
        w.hc_tm.push({ e: 'track', p: { event_type: 'af_conversion' } });
    }
}

export function AdsDefender(props: ADParams) {
    const [show, setShow] = useState<boolean>(false)

    const { adsDefenderId } = props

    useEffect(() => {
        // performance.mark is being used as a feature use signal. While it is traditionally used for performance
        // benchmarking it is low overhead and thus considered safe to use in production and it is a widely available
        // existing API.
        // The performance measurement will be handled by Chrome Aurora

        performance.mark('mark_feature_usage', {
            detail: {
                feature: 'tp-third-parties-ads_defender',
            },
        })
    }, [])

    useEffect(() => {
        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (true === consent?.advertising) {
                setShow(true);
            }
        }

        if (false === show) {
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    });

    return (
        <>
            <Script id={'_tp-ads-def'} src={`https://cdn.adsdefender.com/oti.js?cid=${adsDefenderId}`} />
            {(show === true && adsDefenderId) && (
                <Script id={'_tp-ads-def__af_aud'}>
                    {`
                    window.hc_tm = window.hc_tm || [];
                    window.hc_tm.push({e:'track',p:{event_type:'af_aud'}});
                `}
                </Script>
            )}
        </>
    )
}
