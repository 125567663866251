'use client'

import type { GAEventComponentProps } from "."

import { useEffect, useState } from "react";
import googleSendEvent from "@helpers/google-send-event";

const GAEventComponent = ({ ntmData: { eventName, ...ntmData } }: GAEventComponentProps): JSX.Element | null => {
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (true === consent?.advertising) {
                googleSendEvent(eventName, ntmData);
                setLoaded(true);
            }
        }

        if (false === loaded) {
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    });

    return <></>
}

export default GAEventComponent
